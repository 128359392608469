import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { IoTrophySharp } from 'react-icons/io5';
// import moment from 'moment';

// import { __DEV__ } from 'utils';

// components
import {
  Toolbar,
  MainAreaWrapper,
  InnerMainArea,
  Grid,
  Row,
  Col,
} from 'components/generic/Layout';
import SearchBarUsers from 'components/SearchBarUsers';
import MediaListItem from 'components/MediaListItem';
import FeaturedUser from 'components/FeaturedUser';
//import FeaturedPackage from 'components/FeaturedPackage';
import FeaturedCompetition from 'components/FeaturedCompetition';
import ModalWrapper from 'components/generic/ModalWrapper';
import ContentLoader from 'components/generic/ContentLoader';
import { LinkButton } from 'components/AuthButton';
import AffiliateLink from 'components/AffiliateLink';
import { HammerLogo } from 'components/generic/Logos';
import BGA from 'images/begambleaware.png';
import BGAWhite from 'images/begambleaware_white.png';

// modals
import BuyPackage from 'components/modals/BuyPackage';

// actions
import {
  resetStripeErrors,
  // getLeaderboard
} from 'actions';

// constants
import { DEFAULT_MAX_NUMBER_RECOMMENDED_USERS_DISPLAYED } from 'utils/index';

export default function MarketplaceDesktop() {
  const [modalIsOpen, setModalisOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  // redux props
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    following: state.publicUserReducer.following,
    fetchError: state.publicUserReducer.fetchError,
    featured: state.featuredReducer.featured,
    featuredIsLoading: state.featuredReducer.isLoading,
    featuredFetchError: state.featuredReducer.fetchError,
    recommended: state.featuredReducer.recommended,
    recommendedNext: state.featuredReducer.recommendedNext,
    isLoadingRecommended: state.featuredReducer.isLoadingRecommended,
    fetchRecommendedError: state.featuredReducer.fetchRecommendedError,
    followingNext: state.publicUserReducer.followingNext,
    isLoadingFollowing: state.publicUserReducer.isLoadingFollowing,
    leaderboard: state.publicUserReducer.leaderboard,
    affiliates: state.affiliatesReducer.affiliates,
    competitions: state.competitionsReducer.competitions,
    competitionsIsLoading: state.featuredReducer.isLoadingCompetitions,
    competitionsFetchError: state.featuredReducer.fetchCompetitionsError,
    hammerUsers: state.featuredReducer.hammerUsers,
    isLoadingHammer: state.featuredReducer.isLoadingHammer,
    fetchHammerError: state.featuredReducer.fetchHammerError,
    theme: state.settingsReducer.theme,
  }));

  // redux dispatch
  const dispatch = useDispatch();

  const {
    user,
    following,
    fetchError,
    featured,
    featuredIsLoading,
    featuredFetchError,
    recommended,
    isLoadingRecommended,
    fetchRecommendedError,
    isLoadingFollowing,
    affiliates,
    competitions,
    competitionsIsLoading,
    competitionsFetchError,
    hammerUsers,
    isLoadingHammer,
    fetchHammerError,
    theme,
  } = reduxProps;

  const mediaUsers = featured.filter(f => f.media_account);

  const featuredUsers = featured.filter(f => f.is_featured);
  const featuredPackages = featured.filter(f => f.type === 'featured_packages');

  let age = '18+';
  let rg_url = 'https://www.gambleaware.org';
  let rg_string = 'www.gambleaware.org';
  if (user.state === 'Ontario') {
    age = '19+';
    rg_string = 'Connexontario.ca';
    rg_url = 'https://www.connexontario.ca/';
  }

  // useEffect(() => {
  //   if (leaderboard.length !== 3) {
  //     let today = moment();
  //     let lastWeekDate = moment(today).subtract(__DEV__ ? 100 : 7, 'days');
  //     dispatch(
  //       getLeaderboard(
  //         3,
  //         null,
  //         'roi',
  //         lastWeekDate,
  //         today,
  //         false,
  //         __DEV__ ? 1 : 10 // min number of bets
  //       )
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [leaderboard.length]);

  return (
    <>
      <ModalWrapper
        modalIsOpen={modalIsOpen}
        onClose={() => {
          dispatch(resetStripeErrors());
          setModalisOpen(false);
        }}
        onRequestClose={() => {
          dispatch(resetStripeErrors());
          setModalisOpen(false);
        }}
        modalSize={'small'}
      >
        <BuyPackage
          selectedPackage={selectedPackage}
          dismissModal={() => {
            dispatch(resetStripeErrors());
            setModalisOpen(false);
          }}
        />
      </ModalWrapper>

      <Toolbar style={{ padding: 0 }}>
        <Row
          style={{
            maxWidth: '966px',
            margin: 'auto',
            alignItems: 'center',
          }}
        >
          <SearchBarUsers />
          <LinkButton
            to="/leaderboard"
            containerStyle={{ margin: '0 0 0 var(--space-sm)' }}
          >
            View Leaderboard
          </LinkButton>
        </Row>
      </Toolbar>
      <MainAreaWrapper>
        <InnerMainArea style={{ alignItems: 'stretch' }}>
          <div
            style={{
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              padding: '0 var(--space-sm)',
              boxSizing: 'border-box',
              paddingBottom: 'var(--space-xl)',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: '1266px',
                margin: '0 auto',
                paddingBottom: 'var(--space-xxxl)',
              }}
            >
              {featuredFetchError ? (
                <h5>
                  Sorry, we ran into an error getting featured content. Check
                  back later!
                </h5>
              ) : (
                <>
                  <h4>Media Picks</h4>
                  {featuredIsLoading && mediaUsers.length === 0 && (
                    <Row style={{ boxSizing: 'border-box' }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8].map(i => (
                        <Col
                          key={`feature-user-loader-${i}`}
                          style={{ minHeight: '133px' }}
                        >
                          <ContentLoader
                            height={'70px'}
                            style={{
                              width: '70px',
                              borderRadius: '100%',
                              marginBottom: '8px',
                            }}
                          />
                          <ContentLoader
                            height={'20px'}
                            style={{
                              width: '66%',
                              margin: 'auto',
                              marginBottom: '5px',
                            }}
                          />
                          <ContentLoader
                            height={'20px'}
                            style={{ width: '66%', margin: 'auto' }}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                  {mediaUsers.length === 0 && !featuredIsLoading ? (
                    <p>No media picks right now. Check back later!</p>
                  ) : (
                    <Row style={{ boxSizing: 'border-box' }}>
                      {mediaUsers.map((media, i) => (
                        <MediaListItem
                          key={`marketplace-mediapicks-${media.id}-${i}`}
                          user={media}
                        />
                      ))}
                    </Row>
                  )}

                  <h4>Featured Users</h4>
                  <Grid style={{ boxSizing: 'border-box' }}>
                    {featuredIsLoading &&
                      featuredUsers.length === 0 &&
                      [1, 2, 3, 4, 5, 6, 7].map(i => (
                        <ContentLoader
                          key={`feature-user-loader-${i}`}
                          height={'145px'}
                          style={{ borderRadius: 'var(--std-border-radius)' }}
                        />
                      ))}
                    {featuredUsers.map((u, i) => (
                      <FeaturedUser
                        key={`featured-user-${u.id}-${i}`}
                        user={u}
                        hightlightStats={['roi', 'clv']}
                        featured
                      />
                    ))}
                  </Grid>

                  {fetchHammerError ? (
                    <h5>
                      Sorry, we ran into an error getting Hammer creators. Check
                      back later!
                    </h5>
                  ) : (
                    <>
                      <h4>
                        Hammer Creators
                        <HammerLogo
                          style={{ margin: '0 var(--space-xs)' }}
                          height={18}
                        />
                      </h4>

                      <Grid style={{ boxSizing: 'border-box' }}>
                        {isLoadingHammer &&
                          hammerUsers.length === 0 &&
                          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
                            <ContentLoader
                              key={`more-bettor-loader-${i}`}
                              height={`145px`}
                              style={{
                                borderRadius: 'var(--std-border-radius)',
                              }}
                            />
                          ))}
                        {hammerUsers
                          .slice(
                            0,
                            DEFAULT_MAX_NUMBER_RECOMMENDED_USERS_DISPLAYED
                          )
                          .map((bettor, i) => (
                            <FeaturedUser
                              key={`more-bettor-${bettor.id}-${i}`}
                              style={{ border: '1px solid #47B856' }}
                              user={bettor}
                              hightlightStats={['roi', 'clv']}
                              featured
                            />
                          ))}
                      </Grid>
                    </>
                  )}

                  {/*
                  <h4>Featured Packages</h4>
                  <Grid
                    style={{
                      gridTemplateColumns:
                        'repeat(auto-fill, minmax(400px, 1fr))',
                    }}
                  >
                    {featuredIsLoading &&
                      featuredPackages.length === 0 &&
                      [1, 2, 3, 4, 5].map(i => (
                        <ContentLoader
                          key={`feature-package-loader-${i}`}
                          height={'130px'}
                          style={{ borderRadius: 'var(--std-border-radius)' }}
                        />
                      ))}
                    {featuredPackages.map((p, i) => (
                      <FeaturedPackage
                        key={`feature-package-${p.id}-${i}`}
                        pack={p}
                        onClick={() => {
                          setSelectedPackage(p);
                          setModalisOpen(true);
                        }}
                      />
                    ))}
                  </Grid>
                  */}
                </>
              )}
              {competitions && competitions.length > 0 && (
                <>
                  <Row
                    style={{
                      marginTop: 'var(--space-lg)',
                      marginBottom: 'var(--space-xs)',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h4 style={{ margin: 0 }}>Featured Competitions</h4>
                  </Row>

                  <Row
                    style={{
                      marginBottom: 'var(--space-xs)',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {competitionsFetchError ? (
                      <h5>
                        Sorry, we ran into an error getting competitions. Check
                        back later!
                      </h5>
                    ) : (
                      <>
                        <Grid
                          style={{
                            gridTemplateColumns:
                              'repeat(auto-fill, minmax(400px, 1fr))',
                          }}
                        >
                          {competitionsIsLoading &&
                            competitions.length === 0 &&
                            [1].map(i => (
                              <ContentLoader
                                key={`competition-loader-${i}`}
                                height={'300px'}
                                style={{
                                  borderRadius: 'var(--std-border-radius)',
                                }}
                              />
                            ))}

                          {competitions.map((c, i) => (
                            <FeaturedCompetition
                              key={`feature-competition-${c.id}-${i}`}
                              competition={c}
                            />
                          ))}
                        </Grid>
                      </>
                    )}
                  </Row>
                </>
              )}

              <Row
                style={{
                  marginTop: 'var(--space-lg)',
                  marginBottom: 'var(--space-xs)',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h4 style={{ margin: 0 }}>Following</h4>
                {user.username && (
                  <LinkButton to={`/u/${user.username}`}>
                    View My Profile
                  </LinkButton>
                )}
              </Row>
              {fetchError && (
                <h6>
                  Sorry, we ran into an error getting users you follow. Check
                  back later!
                </h6>
              )}
              <Grid
                style={{
                  boxSizing: 'border-box',
                  gridTemplateColumns: 'repeat(auto-fit, 310px)',
                }}
              >
                {isLoadingFollowing &&
                  following.length === 0 &&
                  [1, 2, 3, 4, 5].map(i => (
                    <ContentLoader
                      key={`following-user-loader-${i}`}
                      height={'130px'}
                      style={{ borderRadius: 'var(--std-border-radius)' }}
                    />
                  ))}
                {following.slice(0, 5).map((u, i) => (
                  <FeaturedUser
                    key={`following-user-${u.id}-${i}`}
                    style={{ border: 'none' }}
                    user={u}
                    hightlightStats={['pending', 'roi', 'clv']}
                    showRecord
                    disablePopup
                  />
                ))}
                {!isLoadingFollowing &&
                  !fetchError &&
                  following.length === 0 && (
                    <p>
                      You're not following anyone! Check out the featured users
                      above.
                    </p>
                  )}
              </Grid>

              {/* {leaderboard.length >= 3 && (
                <>
                  <br />
                  <br />
                  <Row
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h4 style={{ margin: 0 }}>betstamp Top 3</h4>
                    <LinkButton to="/leaderboard" containerStyle={{ margin: 0 }}>
                      View Full Leaderboard
                    </LinkButton>
                  </Row>

                  <small>
                    <i>
                      Top 3 users by ROI with at least 10 bets in the last 7
                      days
                    </i>
                  </small>

                  <Row>
                    <Col
                      style={{
                        boxSizing: 'border-box',
                        padding: '0 var(--space-md)',
                        paddingTop: 'var(--space-xxl)',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <IoTrophySharp
                        size={48}
                        color={'#cd7f32'}
                        style={{
                          marginBottom: 'var(--space-sm)',
                          padding: 'var(--space-sm)',
                          backgroundColor: '#020100',
                          borderRadius: '48px',
                          height: '48px',
                          width: '48px',
                        }}
                      />
                      <FeaturedUser
                        key={`leaderboard-marketplace-user-${leaderboard[2].id}`}
                        style={{ borderColor: '#cd7f32', width: '100%' }}
                        user={leaderboard[2]}
                        hightlightStats={['roi']}
                        showRecord
                      />
                    </Col>
                    <Col
                      style={{
                        boxSizing: 'border-box',
                        padding: '0 var(--space-md)',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <IoTrophySharp
                        size={48}
                        color={'#ffd700'}
                        style={{
                          marginBottom: 'var(--space-sm)',
                          padding: 'var(--space-sm)',
                          backgroundColor: '#141100',
                          borderRadius: '48px',
                          height: '48px',
                          width: '48px',
                        }}
                      />
                      <FeaturedUser
                        key={`leaderboard-marketplace-user-${leaderboard[0].id}`}
                        style={{ borderColor: '#ffd700', width: '100%' }}
                        user={leaderboard[0]}
                        hightlightStats={['roi']}
                        showRecord
                      />
                    </Col>
                    <Col
                      style={{
                        boxSizing: 'border-box',
                        padding: '0 var(--space-md)',
                        paddingTop: 'var(--space-lg)',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <IoTrophySharp
                        size={48}
                        color={'#c0c0c0'}
                        style={{
                          marginBottom: 'var(--space-sm)',
                          padding: 'var(--space-sm)',
                          backgroundColor: '#060606',
                          borderRadius: '48px',
                          height: '48px',
                          width: '48px',
                        }}
                      />
                      <FeaturedUser
                        key={`leaderboard-marketplace-user-${leaderboard[1].id}`}
                        style={{ borderColor: '#c0c0c0', width: '100%' }}
                        user={leaderboard[1]}
                        hightlightStats={['roi']}
                        showRecord
                      />
                    </Col>
                  </Row>
                </>
              )} */}

              {affiliates.length > 0 && (
                <>
                  <h4>Featured Books</h4>
                  <Grid
                    style={{
                      gridTemplateColumns:
                        'repeat(auto-fit, minmax(300px, 1fr))',
                    }}
                  >
                    {affiliates.map(book => (
                      <AffiliateLink
                        key={`accounting-affiliate-marketplace-desktop-${book.id}`}
                        shape="box"
                        book={book}
                        fromPage={'Marketplace Web'}
                      />
                    ))}
                  </Grid>
                  <p>
                    Opening
                    <b> as many sportsbook accounts </b>
                    as possible guarantees you get the best odds and the biggest
                    bonuses.
                  </p>
                  <p>
                    <i>
                      When you sign up by clicking above, betstamp gets a
                      referral fee and you get the signup bonus. This helps us
                      improve the app and keep it free to use.
                    </i>
                  </p>
                  <p>
                    <b>Thanks for your support!</b>
                  </p>
                </>
              )}
              <Row
                style={{ alignItems: 'center', marginTop: 'var(--space-md)' }}
              >
                <Col
                  style={{
                    flex: '0 0 42px',
                    width: '42px',
                    height: '42px',
                    border: '2px solid var(--color-danger)',
                    borderRadius: '42px',
                    fontWeight: 'bold',
                    marginRight: 'var(--space-xs)',
                  }}
                >
                  {age}
                </Col>

                <span style={{ flex: 1 }}>
                  Please Gamble Responsibly. Visit{' '}
                  <a href={rg_url}>{rg_string}</a> for more info.
                </span>
              </Row>
              {user.state != 'Ontario' &&
                (theme === 'dark' ? (
                  <img
                    style={{ height: '32px', width: 'auto' }}
                    src={BGAWhite}
                    alt="Be Gamble Aware"
                  />
                ) : (
                  <img
                    style={{ height: '32px', width: 'auto' }}
                    src={BGA}
                    alt="Be Gamble Aware"
                  />
                ))}
            </div>
          </div>
        </InnerMainArea>
      </MainAreaWrapper>
    </>
  );
}
